<!--
 * @Description: 课程资源管理
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:43:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-06-23 16:16:46
-->
<template>
  <div class="class-list">
    <table-list
      title="班级列表"
      :loading="loading"
      :data="classList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :operates2="operates2(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: false }"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  // {
  //   label: '班级数据统计',
  //   method: _this.handleTotal,
  // },
  {
    label: '创建班级',
    method: _this.handleCreate,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '班级名称',
    prop: 'className',
    width: 150,
  },
  {
    label: '班主任姓名',
    prop: 'headTeacherName',
    width: 120,
  },
  {
    label: '班级状态',
    type: 'select',
    width: 120,
    prop: 'classStatus',
    children: [
      { value: 1, label: '制定培训计划' },
      { value: 2, label: '设置对外招生' },
      { value: 3, label: '进行中' },
      { value: 4, label: '结训完成' },
    ],
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'className',
    label: '班级名称',
    width: 200,
  },
  {
    prop: 'headTeacherNames',
    label: '班主任',
    width: 150,
  },
  {
    prop: 'classStudentCount',
    label: '班级人数',
    width: 120,
    align: 'center',
  },
  {
    prop: 'classDurationMinutes',
    label: '总培训时长(分钟)',
    width: 250,
    align: 'center',
    formatter: row => {
      return row.classDurationMinutes
    },
  },
  {
    label: '预计培训时间',
    formatter: row => {
      return row.classBeginTime + '~' + row.classEndTime
    },
    width: 350,
  },
  {
    prop: 'createTime',
    label: '创建时间',
    width: 200,
  },
  {
    prop: 'classStatusStr',
    label: '班级状态',
    width: 200,
    renderHeader(h, { column }) {
      const inReview1 = '班级状态说明：'
      const inReview2 = '1、定制培训计划'
      const inReview3 = '创建班级后，需要为班级定制培训计划'
      const inReview4 = '2、设置对外招生'
      const inReview5 =
        '点击设置对外招生把当前班级需要生成商品对外发布。班级就会发布到明职道的学院班级中。用户可以进行报名。点击已设置变更状态，可以看到学员的学习情况。'
      const inReview6 = '3、进行中'
      const inReview7 = '班级进行中，可以看到学员的学习情况。'
      const inReview8 = '点击结训完成，将关闭班级。关闭前需要下架对应的商品。'
      const inReview9 = '4、已结训完成'
      const inReview10 = '支持复制班级重新开班，查看学员学习记录等。'
      return h('div', [
        h('span', column.label),
        h(
          'el-tooltip',
          {
            props: {
              placement: 'bottom',
            },
          },
          [
            h(
              'div',
              {
                slot: 'content',
                style: {
                  width: '300px',
                  whiteSpace: 'normal',
                  'margin-top': '10px',
                  'margin-bottom': '10px',
                  'text-align': 'center',
                  'font-size': '14px',
                },
              },
              inReview1,
            ),
            h(
              'div',
              {
                slot: 'content',
                style: {
                  width: '300px',
                  whiteSpace: 'normal',
                  'margin-bottom': '10px',
                },
              },
              inReview2,
            ),
            h(
              'div',
              {
                slot: 'content',
                style: {
                  width: '300px',
                  whiteSpace: 'normal',
                  'margin-bottom': '10px',
                },
              },
              inReview3,
            ),
            h(
              'div',
              {
                slot: 'content',
                style: {
                  width: '300px',
                  whiteSpace: 'normal',
                  'margin-bottom': '10px',
                },
              },
              inReview4,
            ),
            h(
              'div',
              {
                slot: 'content',
                style: {
                  width: '300px',
                  whiteSpace: 'normal',
                  'margin-bottom': '10px',
                },
              },
              inReview5,
            ),
            h(
              'div',
              {
                slot: 'content',
                style: {
                  width: '300px',
                  whiteSpace: 'normal',
                  'margin-bottom': '10px',
                },
              },
              inReview6,
            ),
            h(
              'div',
              {
                slot: 'content',
                style: {
                  width: '300px',
                  whiteSpace: 'normal',
                  'margin-bottom': '10px',
                },
              },
              inReview7,
            ),
            h(
              'div',
              {
                slot: 'content',
                style: {
                  width: '300px',
                  whiteSpace: 'normal',
                  'margin-bottom': '10px',
                },
              },
              inReview8,
            ),
            h(
              'div',
              {
                slot: 'content',
                style: {
                  width: '300px',
                  whiteSpace: 'normal',
                  'margin-bottom': '10px',
                },
              },
              inReview9,
            ),
            h(
              'div',
              {
                slot: 'content',
                style: {
                  width: '300px',
                  whiteSpace: 'normal',
                  'margin-bottom': '10px',
                },
              },
              inReview10,
            ),
            h('i', {
              class: 'el-icon-question',
              style: 'color:#979797;margin-left:5px;pointer-events: auto;',
            }),
          ],
        ),
      ])
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '修改班级信息',
    isShow: row => row.classStatus == 1,
    method: _this.handleEdit,
  },
  {
    label: '制定培训计划',
    isShow: row => row.classStatus == 1,
    method: _this.handleCoursePlans,
  },
  {
    label: '确认班级信息',
    isShow: row => row.classStatus == 1,
    method: _this.handleMakeSure,
  },
  {
    label: '已设置对外招生',
    isShow: row => row.classStatus == 2,
    method: _this.handleZhaoSheng,
  },
  {
    label: '设置对外招生',
    isShow: row => row.classStatus == 2,
    method: _this.handleZhaoShengStart,
  },
]
const operates2 = _this => [
  {
    label: '取消班级',
    isShow: row => row.classStatus == 2,
    color: '#979797',
    method: _this.handleCancel,
  },
  {
    label: '班级详情',
    isShow: row => row.classStatus == 3 || row.classStatus == 4,
    method: _this.classInfo,
  },
  {
    label: '学习详情',
    isShow: row => row.classStatus == 3 || row.classStatus == 4,
    method: _this.handleStudyInfo,
  },
  {
    label: '导出学习记录',
    isShow: row => row.classStatus == 3 || row.classStatus == 4,
    method: _this.handleStudyExport,
  },
  {
    label: '结训完成',
    isShow: row => row.classStatus == 3,
    color: '#979797',
    method: _this.handleComplete,
  },
]
// 导出表格列配置
const Exportcolumn = () => [
  {
    prop: 'studentName',
    label: '学员名称',
  },
  {
    prop: 'phone',
    label: '手机号码',
  },
  {
    prop: 'firstStudyTime',
    label: '首次开始学习时间',
  },
  {
    prop: 'continuityStudyMax',
    label: '连续学习最大天数',
  },
  {
    prop: 'studyLengthMin',
    label: '已学时长（分钟）',
  },
  {
    prop: 'lastStudyTime',
    label: '最后一次学习时间',
  },
]
import TableList from '@/components/TableList'
import {
  classList,
  classStatus,
  classcancel,
  studentInfo,
  changeClassStatus,
  classfinish,
} from '@/api/class'
import to from 'await-to'
export default {
  name: 'ClassList',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      operates2,
      loading: false,
      classList: [],
      // selectClass: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      Exportcolumn,
    }
  },
  mounted() {
    this.getClasses()
  },
  methods: {
    async getClasses() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(classList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.classList = res.data.list
      this.pager.total = res.data.total
      this.classList.map(item => {
        if (item.availableStatus == 0) {
          this.$set(item, 'enable', false)
        } else if (item.availableStatus == 1) {
          this.$set(item, 'enable', true)
        }
      })
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getClasses()
    },
    async handleStatusChange(classId, enable, row) {
      var availableStatus = 0
      if (enable == true) {
        availableStatus = 1
      } else if (enable == false) {
        availableStatus = 0
      }
      const [, err] = await to(classStatus({ classId, availableStatus }))
      if (err) return this.$message.warning(err.msg)
      row.enable = !row.enable
    },
    handleTotal() {
      this.$router.push('/course/classManger/classTotal')
    },
    handleCreate() {
      this.$router.push('/course/classManger/createclass')
    },
    handleCoursePlans(row) {
      this.$router.push({
        path: '/course/classManger/courseAdd',
        query: { classId: row.classId },
      })
    },
    handleMakeSure(row) {
      this.$router.push({
        path: '/course/classManger/classSure',
        query: { classId: row.classId },
      })
    },
    async handleZhaoSheng(row) {
      const [, err] = await to(changeClassStatus({ classId: row.classId, classStatus: 3 }))
      if (err) return this.$message.warning(err.msg)
      location.reload()
    },
    handleZhaoShengStart(row) {
      this.$router.push({
        path: '/scenter/sallManger/add',
        query: { classId: row.classId },
      })
    },
    async handleCancel(row) {
      const [, err] = await to(classcancel({ classId: row.classId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('取消班级成功')
      location.reload()
    },
    async handleComplete(row) {
      const [, err] = await to(classfinish({ classId: row.classId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('结训完成成功')
      location.reload()
    },
    classInfo(row) {
      this.$router.push({
        path: '/course/classManger/classInfo',
        query: { classId: row.classId, status: row.classStatusStr },
      })
    },
    handleStudyInfo(row) {
      this.$router.push({
        path: '/course/classManger/studyInfo',
        query: { classId: row.classId },
      })
    },
    async handleStudyExport(row) {
      let obj = {
        size: 10000,
        current: 1,
        classId: row.classId,
      }
      const [res, err] = await to(studentInfo(obj))
      if (err) return this.$message({ type: 'error', message: err.msg })
      // 导出表格的表头设置
      let allColumns = Exportcolumn(this)
      var columnNames = []
      var columnValues = []
      for (var i = 0; i < allColumns.length; i++) {
        columnNames[i] = allColumns[i].label
        columnValues[i] = allColumns[i].prop
      }
      require.ensure([], () => {
        const { export_json_to_excel } = require('@/vendor/Export2Excel')
        const tHeader = columnNames
        const filterVal = columnValues
        const list = res.data.list
        const data = this.formatJson(filterVal, list)
        const tableName = '' + row.className + '' + '学习记录'
        export_json_to_excel(tHeader, data, tableName)
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        path: '/course/classManger/edit',
        query: { classId: row.classId },
      })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getClasses()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getClasses()
    },
  },
}
</script>
<style lang="scss" scoped>
.class-list {
  tr {
    &:last-of-type {
      width: 300px;
    }
  }
  // ::v-deep.operate-group {
  //   width: 50%;
  //   float: left;
  //   span {
  //     display: block;
  //     margin: 0;
  //   }
  // }
}
</style>
